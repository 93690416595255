
import { faTruck, faBoxOpen, faHammer } from '@fortawesome/free-solid-svg-icons';

const servicesData = [
  {
    icon: faTruck,
    name: 'Movers',
    description: 'Efficient and secure residential moving services to help you relocate your home with ease.',
  },
  {
    icon: faBoxOpen,
    name: 'Storage',
    description: 'Professional packing services to ensure your belongings are protected during the move.',
  },
  {
    icon: faHammer,
    name: 'Carpentery Services',
    description: 'Skilled carpentry for custom woodwork, repairs, and more—crafted with precision and care.',
  },
  // Add more services as needed
];

export default servicesData;
