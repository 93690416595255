import React from 'react';
import '../Style/HeroSection.css'; // Import the CSS file for styling
import { TypeAnimation } from 'react-type-animation';
import { useTranslation } from 'react-i18next';

const HeroSection = () => {
  const { t } = useTranslation();
  return (
    <div className="hero-section d-flex flex-column">
   


        <TypeAnimation
          sequence={[
          
            t('Movers Star Express Mover'),
            1000, 
            t('Movers star Express Packers'),
          
          ]}
          wrapper="span"
          speed={50}
         
          repeat={Infinity}
          className="type-animation"
        />
        <p className="hero-paragraph">
       {t(' Your trusted partner in seamless and reliable moving and packing services.')}
      </p>
       <a href="https://wa.me/971507742190" target="_blank" rel="noopener noreferrer">
           <div className='button'>
            <div className='btn btn'>{t('Order Now')}</div>
           </div>
       </a>
    </div>
  );
};

export default HeroSection;
