import React from 'react';
import '../Style/Portfolio.css';
import portfolioItems from '../Objects/protfolioItems';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const PortfolioComponent = () => {
  const { t } = useTranslation();


  return (
    <div className="portfolio-section">
    <Container className='mt-5' >
    <h2>{t('Our Work')}</h2>
    <div className="portfolio-grid">
      {portfolioItems.map((item) => (
        <div key={item.id} className="portfolio-item">
          <img src={item.image} alt={item.title} />
          <div className="portfolio-overlay">
            <h3>{t(item.title)}</h3>
            <p>{t(item.description)}</p>
          </div>
        </div>
      ))}
    </div>
    </Container>
    </div>
  );
};

export default PortfolioComponent;
