import React from 'react';
import NavBar from '../Components/NavBar';
import HeroSection from './HeroSection';
import  About  from './About';
import Services from './Services';
import Testimonials from './Testimonials';
import PortfolioComponent from './PortfolioComponent';
import Footer from './Footer';
import ContactUs from './ContactUs';
import TopHeader from './TopHeader';


const Home = () => {
  return (
    <div>
    <TopHeader />
    <NavBar />
    <HeroSection />
      <section id="about"  className="secondary-background">
        <About />
      </section>
      <section id="services" className="secondary-background">
        <Services />
      </section>
      <section id="testimonials"  className="primary-background">
        <Testimonials />
      </section>
      <section id="portfolio-section" className="secondary-background">
      <PortfolioComponent />
    </section>
    <section id="contact-us" className="primary-background">
    <ContactUs />
  </section>

    <Footer />
    </div>
  );
};

export default Home;
