/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import '../Style/FixedIcons.css'; // Assuming you create this file for CSS

const phoneNumber = '+971507742190';

const handlePhoneCall = () => {
  window.location.href = `tel:${phoneNumber}`;
};



const FixedIcons = () => {
  return (
    <>
      <div className="fixed-icon left-icon">
        <a href='#' onClick={handlePhoneCall}> <FontAwesomeIcon icon={faPhone} />
        </a>
      </div>
      <div className="fixed-icon right-icon">
        <a href="https://wa.me/971507742190" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faWhatsapp} />
        </a>
      </div>
    </>
  );
};

export default FixedIcons;
