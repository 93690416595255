/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import {FaInstagram, FaWhatsapp } from 'react-icons/fa';
import '../Style/TopHeader.css'




const phoneNumber = '+971507742190';

const handlePhoneCall = () => {
  window.location.href = `tel:${phoneNumber}`;
};

const TopHeader = () => {
  return (
    <div style={{ backgroundColor: 'var(--secondary-color)', color: 'var(--primary-color)', padding: '20px 0' }} className='TopHeader'>
      <Container fluid>
        <Row className="align-items-center">
          <Col md={6}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
            
          
              <a href="#" onClick={handlePhoneCall}> <FontAwesomeIcon icon={faPhoneAlt} /><span style={{ margin: '0 09px', cursor: 'pointer', width:'30px', height: '23px' }} >+971507742190</span></a>
            </div>
          </Col>
          <Col md={6} style={{ textAlign: 'right', marginTop:'3px'}}>
            <a href="https://wa.me/971507742190" target="_blank" rel="noopener noreferrer"><FaWhatsapp style={{ margin: '0 10px', cursor: 'pointer', width:'30px', height: '23px' }}  /></a>
           <a href="https://www.instagram.com/moversstarexpressa?igsh=MWV1c20zM3RtNGlqMw==" target="_blank" rel="noopener noreferrer"><FaInstagram style={{ margin: '0 10px', cursor: 'pointer', width:'30px', height: '23px', color:'var(--primary-color)' }}  /></a>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TopHeader;
