import React from 'react';
import { useTranslation } from 'react-i18next';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import '../Style/LanguageSwitcher.css';

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  // Determine the active language
  const currentLanguage = i18n.language;

  return (
    <DropdownButton id="dropdown-basic-button" title={currentLanguage === 'en' ? 'English' : 'عربيّ'} className="language-switcher">
      <Dropdown.Item onClick={() => changeLanguage('en')} active={currentLanguage === 'en'}>English</Dropdown.Item>
      <Dropdown.Item onClick={() => changeLanguage('ar')} active={currentLanguage === 'ar'}>عربيّ</Dropdown.Item>
    </DropdownButton>
  );
}

export default LanguageSwitcher;
