import React from 'react';
import '../Style/ContactUs.css'; // Import your CSS file for styling
import { useTranslation } from 'react-i18next';

const ContactUs = () => {
  const { t } = useTranslation();
  return (
    <div className="contact-us-section ">
    <h2 className='text-center'>{t('Contact Us')}</h2>
      <div className="contact-us-content">
        {/* Left side: Form */}
        <div className="contact-form">
          <form action="https://formspree.io/f/your-form-id" method="POST">
            <div className="form-group">
              <label htmlFor="name">{t('Name')}</label>
              <input type="text" id="name" name="name" required />
            </div>
            <div className="form-group">
              <label htmlFor="email">{t('Email')}</label>
              <input type="email" id="email" name="email" required />
            </div>
            <div className="form-group">
              <label htmlFor="message">{t('Message')}</label>
              <textarea id="message" name="message" rows="5" required></textarea>
            </div>
            <button type="submit">{t('Send')}</button>
          </form>
        </div>

        {/* Right side: Image */}
        <div className="contact-image" id='contact-image'>
          <img className='contact-image' src="https://images.pexels.com/photos/796602/pexels-photo-796602.jpeg?auto=compress&cs=tinysrgb&w=800" alt="Contact Us" />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
