import prot1 from '../Assests/protfolio1.jpg';
import prot2 from '../Assests/protfolio2.webp';
import prot3 from '../Assests/protfolio3.jpg';
const portfolioItems = [
  {
    id: 1,
    image: prot1, // Replace with your image URL
    title: 'Khalifa City',
    description: 'Efficient and careful moving service for residential homes.',
  },
  {
    id: 2,
    image: prot2,// Replace with your image URL
    title: 'Shahama',
    description: 'Reliable corporate relocation services for businesses.',
  },
  {
    id: 3,
    image: prot3, // Replace with your image URL
    title: 'Al Raha Beach',
    description: 'Expert handling of international shipping and logistics.',
  },
  // Add more items as needed
];
export default portfolioItems