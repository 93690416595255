import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Offcanvas } from 'react-bootstrap';
import { Link } from 'react-scroll';
import '../Style/NavBar.css';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';

const TopNavbar = () => {
  const [show, setShow] = useState(false);
  const [bgColor, setBgColor] = useState('var(--primary-color)'); 
  const { t } = useTranslation();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleScroll = () => {
    const sections = document.querySelectorAll('section');
    let scrollPosition = window.scrollY;

    sections.forEach(section => {
      const sectionTop = section.offsetTop;
      const sectionHeight = section.clientHeight;

      if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
        if (section.classList.contains('primary-background')) {
          setBgColor('var(--secondary-color)');
        } else if (section.classList.contains('secondary-background')) {
          setBgColor('var(--primary-color)');
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
      <Navbar variant="light" expand="lg" className="NavBar" style={{ backgroundColor: bgColor }}>
        <Navbar.Brand href="LandPage">{t('MOVERS Star Express')}</Navbar.Brand>
        <div className='languageChanger'><LanguageSwitcher /></div>
        <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={handleShow} />
        <Navbar.Collapse id="offcanvasNavbar" className="justify-content-start">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="LandPage" smooth={true} duration={500}>{t('Home')}</Nav.Link>
            <Nav.Link as={Link} to="about" smooth={true} duration={500}>{t('About')}</Nav.Link>
            <Nav.Link as={Link} to="services" smooth={true} duration={500}>{t('Services')}</Nav.Link>
            <Nav.Link as={Link} to="portfolio-section" smooth={true} duration={500}>{t('Our Work')}</Nav.Link>
            <Nav.Link as={Link} to="contact-us" smooth={true} duration={500}>{t('Contact Us')}</Nav.Link>
            <LanguageSwitcher />
            </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Offcanvas show={show} onHide={handleClose} placement="start" className="offcanvas-nav">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{t('MOVERS Star Express')}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <Nav.Link as={Link} to="home" smooth={true} duration={500} onClick={handleClose}>{t('Home')}</Nav.Link>
            <Nav.Link as={Link} to="about" smooth={true} duration={500} onClick={handleClose}>{t('About')}</Nav.Link>
            <Nav.Link as={Link} to="services" smooth={true} duration={500} onClick={handleClose}>{t('Services')}</Nav.Link>
            <Nav.Link as={Link} to="portfolio-section" smooth={true} duration={500} onClick={handleClose}>{t('Our Work')}</Nav.Link>
            <Nav.Link as={Link} to="contact-us" smooth={true} duration={500} onClick={handleClose}>{t('Contact Us')}</Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default TopNavbar;
