import testimonialPhoto from '../Assests/testimonial.jpeg'; // Import the image

const testimonialsData = [
  {
    name: "Saleem",
    role: "Business man",
    feedback: "Star Express Movers made our relocation smooth and hassle-free. Highly recommend!",
    rating: 5,
    photo: testimonialPhoto,
  },
  {
    name: "John Micel",
    role: "Manager at Techo",
    feedback: "Professional, efficient, and reliable service. Couldn't ask for more!",
    rating: 5,
    photo: testimonialPhoto,
  },
  {
    name: "Saad Ayaz",
    role: "Ceo, Al-saad Ltd",
    feedback: "Great experience with Star Express Packers. My items were handled with care.",
    rating: 4,
    photo: testimonialPhoto,
  }
];
export default testimonialsData