
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import TopHeader  from './Components/TopHeader';
import LandPage from './Components/LandPage';
import FixedIcons from './Components/FixedIcon';


function App() {
  return (
    <div className="App">
     <TopHeader />
     <LandPage />
     <FixedIcons />
    </div>
  );
}

export default App;
