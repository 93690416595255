/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import '../Style/Footer.css'; // Assuming your CSS file is here
import { FaInstagram, FaWhatsapp } from 'react-icons/fa';
import {faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';


const phoneNumber = '+971505077638';

const handlePhoneCall = () => {
  window.location.href = `tel:${phoneNumber}`;
};
const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="footer ">
      <div className="footer-container mt-5">
        <div className="footer-column">
          <h3>{t('Movers Star Express')}</h3>
          <p>{t('Reliable moving services with a focus on care and efficiency for homes and businesses.')}</p>
          <div className="contact-info">
            <p><strong>{t('Phone')}:</strong>+971507742190</p>
            <p><strong>{t('Email')}:</strong>Moversstarexpress...starexp@gmail.com</p>
          </div>
          <div className="social-icons d-flex justify-content-start">
          <a href="#" onClick={handlePhoneCall}> <FontAwesomeIcon icon={faPhoneAlt} className='mt-2'/></a>
            <a href="https://www.instagram.com/moversstarexpressa?igsh=MWV1c20zM3RtNGlqMw==" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
            <a href="https://wa.me/971507742190" target="_blank" rel="noopener noreferrer"><FaWhatsapp /></a>
          </div>
        </div>

        <div className="footer-column">
          <h3>{t('Useful Links')}</h3>
          <ul>
            <li><a href="#services">{t('Services')}</a></li>
            <li><a href="#about">{t('About Us')}</a></li>
            <li><a href="#testimonials">{t('Testimonials')}</a></li>
            <li><a href="#contact">{t('Contact')}</a></li>
          </ul>
        </div>

        <div className="footer-column">
          <h3>{t('More Information')}</h3>
          <ul>
            <li><a href="#">{t('Privacy Policy')}</a></li>
            <li><a href="#">{t('FAQs')}</a></li>
            <li><a href="#">{t('Support')}</a></li>
            <li><a href="#">{t('Join Our Community')}</a></li>
          </ul>
        </div>

    
      </div>
      <div className="footer-bottom">
      &copy; {new Date().getFullYear()} Movers star express. All rights reserved. | Developed by <span className='companyName'>&lt;/&gt;LogicCome.net</span>
      </div>
    </footer>
  );
};

export default Footer;
