/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../Style/Services.css'; // Import the CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import servicesData  from '../Objects/serviceobj'
import { FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const phoneNumber = '+971505077638';

const handlePhoneCall = () => {
  window.location.href = `tel:${phoneNumber}`;
};
const Services = () => {
  const { t } = useTranslation();
  return (
    <section className="services-section">
      <Container className='mt-5'>
        <h2 className="text-center">{t('Our Services')}</h2>
        <Row>
          {servicesData.map((service, index) => (
            <Col md={4} key={index} className="d-flex justify-content-center">
              <div className="service-box">
                <FontAwesomeIcon icon={service.icon} className="service-icon" />
                <h3>{t(service.name)}</h3>
                <p>{t(service.description)}</p>
                <div className="social-icon">
                <a href="#" onClick={handlePhoneCall}> <FontAwesomeIcon icon={faPhoneAlt} /></a>
                <a href="https://www.instagram.com/moversstarexpressa?igsh=MWV1c20zM3RtNGlqMw==" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                <a href="https://wa.me/971507742190" target="_blank" rel="noopener noreferrer"><FaWhatsapp /></a>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Services;
