
import React from 'react';
import '../Style/Testimonials.css'; // Create a CSS file for styling
import testimonialsData from '../Objects/TestimonialsData'
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


const Testimonials = () => {
  const { t } = useTranslation();
  return (
    <div className="testimonials-section">
    <Container className='mt-5'>
    <h2>{t('What Our Clients Say')}</h2>
    <div className="testimonials-row">
      {testimonialsData.map((testimonial, index) => (
        <div className="testimonial-box" key={index}>
          <img src={testimonial.photo} alt={`${testimonial.name}`} className="testimonial-photo" />
          <h3>{t(testimonial.name)}</h3>
          <p className="testimonial-role">{t(testimonial.role)}</p>
          <p className='para'>"{t(testimonial.feedback)}"</p>
          <div className="testimonial-rating">
            {'★'.repeat(testimonial.rating)}{'☆'.repeat(5 - testimonial.rating)}
          </div>
        </div>
      ))}
    </div>
    </Container>
    </div>
  );
};

export default Testimonials;