import React from 'react';
import '../Style/About.css'; 
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();
  return (
    <div className="about-section" >
      <div className="about-content">
        <h2>{t('About Us')}</h2>
        <p>
         {t(' Movers Star Express is a leading provider of moving and packing services, dedicated to ensuring the safe and timely delivery of your goods. With years of experience in the logistics industry, we take pride in offering reliable, efficient, and customer-focused services. Whether you are relocating your home or office, our team of experts is here to handle every aspect of your move with professionalism and care.')}
        </p>
        <p>
          {t('Our mission is to provide top-notch service with a focus on quality, safety, and customer satisfaction. Trust Star Express to be your partner in all your moving and packing needs.')}
        </p>
      </div>
    </div>
  );
};

export default About;
